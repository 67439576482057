<template>
    <div class="intro" @click="goToExhibition">
        <div class="animation">
            <IntroAnimation />
        </div>
        <div class="title">
            <span class="title-part"
                v-for="(part, index) in titleParts"
                :key="`part-${index}`"
                :style="getTitlePartStyle(part)"
                :class="`title-part-${index + 1} title-section-${part.section}`"
            >
                {{ part.text }}
            </span>
        </div>
    </div>
    <div class="skip clickable" @click="goToExhibition()">&lt; Skip Intro &gt;</div>
</template>

<script>
import { useRouter } from 'vue-router'
import IntroAnimation from '@/components/parts/IntroAnimation_2.vue'

export default {
    components: {
        IntroAnimation
    },
    setup() {
        const router = useRouter()

        // setTimeout(goToExhibition, 9000)

        function goToExhibition() {
            router.push('/#start')
            sessionStorage.setItem('started', true)
        }
        function getTitlePartStyle(part) {
            return `padding-left: ${part.indent}rem`
        }
        const titleParts = [
            {
                text: 'Über',
                indent: 2.25,
                section: 1
            },
            {
                text: 'setzen',
                indent: 2,
                section: 1
            },
            {
                text: 'ist Macht',
                indent: 3.5,
                section: 1
            },
            {
                text: 'Geheimnisse',
                indent: 0.25,
                section: 2
            },
            {
                text: 'Geschenke',
                indent: 2,
                section: 2
            },
            {
                text: 'Geschichten',
                indent: .25,
                section: 2
            },
            {
                text: 'in der',
                indent: 0,
                section: 2
            },
            {
                text: 'Frühen',
                indent: 2,
                section: 2
            },
            {
                text: 'Neuzeit',
                indent: 3,
                section: 2
            },
        ]
        return {
            goToExhibition,
            getTitlePartStyle,
            titleParts,
        }        
    },
}
</script>

<style lang="scss" scoped>
.intro {
    min-width: 100vw;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid green;
    .title {
        display: none;
        .title-part {
            display: block;
            font-family: "bluu", serif;
            font-size: 2.8rem;
            text-transform: uppercase;
        }
        .title-section-1 {
            opacity: 0;
            animation: fade-in-1 ease-in forwards;
            animation-delay: 3.8s;
            animation-duration: .8s;
        }
        .title-section-2 {
            opacity: 0;
            animation: fade-in-1 ease-in forwards;
            animation-delay: 5.8s;
            animation-duration: 1s;
        }
    }
    .skip {
        position: fixed;
        bottom: 6rem;
        left: 2rem;
    }
}

@keyframes fade-in-1 {

    100% {
        opacity: 1;
    }
}
</style>