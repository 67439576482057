<template>
    <div class="intro-container">
        <div class="intro-animation">
            <img class="ball-1 ball" src="../../assets/images/Kugel_Geheimnisse.svg">
            <img class="ball-2 ball" src="../../assets/images/Kugel_Geschenke.svg">
            <img class="ball-3 ball" src="../../assets/images/Kugel_Geschichten.svg">
        </div>
    </div>
</template>

<style lang="scss" scoped>
.intro-container {
    border: 2px dotted red;
    width: 100vw;
    height: 100vh;
    .intro-animation {
        position: relative;
        top: 0;
        left: 0;
        .ball {
            position: absolute;
            opacity: .5;
        }
        .ball-1 {
            width: 40vw;
        }
        .ball-2 {
            width: 40vw;
        }
        .ball-3 {
            width: 40vw;
        }
    }
}
</style>